import { useState, useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse' // Import the CSV parsing library
import { FormikProps } from 'formik'
import { IQuoteForm } from 'models/simple_quotes.model'
import { revertNumberFormat } from 'utilities'

interface Props {
	formik: FormikProps<IQuoteForm>
	field: string
	setFieldValue: (field: string, value: string) => void
	value: string
}

export const CreateCSVFile: React.FC<Props> = ({
	formik,
	field,
	setFieldValue,
	value,
}: Props) => {
	const [fileUrl, setFileUrl] = useState<string>(value || '')
	const [fileName, setFileName] = useState<string>('')
	const [fileLoading, setFileLoading] = useState<boolean>(false)

	const onDrop = useCallback(async (files: File[]) => {
		if (files?.length > 0 && files?.length < 2) {
			setFileLoading(true)
			const file = files[0]
			if (file.size <= 20 * 1024 * 1024) {
				const reader = new FileReader()

				reader.onload = (e) => {
					const csvText = e.target?.result as string

					Papa.parse(csvText, {
						header: true,
						skipEmptyLines: true,
						complete: function (results: any) {
							const extractedLines = processCSVData(results.data)
							let quoteLines = []
							const zones = formik.values.zones
							console.log({ extractedLines })

							for (const extractedLine of extractedLines) {
								const zone = zones.find(
									(zone) => zone.zone_label === extractedLine.section
								)
								quoteLines.push({
									id: null,
									zone_id: zone?.zone_id,
									zone_label: zone?.zone_label,
									type: '',
									description: extractedLine.subSection
										? extractedLine.subSection
										: extractedLine.section,
									quantity: Number(extractedLine.quantity),
									unit: extractedLine.unit,
									square_meters: 0,
									total_days: 0,
									erect_and_dismantle: extractedLine?.totalRate.trim()
										? revertNumberFormat(extractedLine?.totalRate.trim())
										: 0,
									percentage_weekly_hire_fee: 10,
									weekly_hire_fee: extractedLine?.extendedHireRate.trim()
										? revertNumberFormat(extractedLine?.extendedHireRate.trim())
										: 0,
									total: extractedLine?.totalRate.trim()
										? revertNumberFormat(extractedLine?.totalRate.trim())
										: 0,
									length: 0,
									height: 0,
									width: 0,
									total_dimensions: 0,
									erect_and_dismantle_is_dirty: false,
									weekly_hire_fee_is_dirty: false,
									hire_period: extractedLine.hirePeriod
										? Number(extractedLine.hirePeriod)
										: 0,
								})
							}
							console.log({ quoteLines })

							formik.setFieldValue('quote_lines', quoteLines)
						},
					})
				}

				setFileLoading(false)
				reader.readAsText(file)
				setFileName(file.name)
			}
		} else {
			setFileLoading(false)
			alert('File size must be less than 20MB')
		}
	}, [])
	const processCSVData = (data: any[]) => {
		let currentSection = ''
		let sectionHasSubSection = false

		const processedData: any[] = []

		for (let i = 0; i < data.length; i++) {
			const row = data[i]
			const product = row['Product']

			if (/^\d+\)/.test(product)) {
				if (currentSection && !sectionHasSubSection) {
					processedData.push({
						section: currentSection,
						subSection: null,
						unit: row['Unit'],
						totalRate: row['Total Rate'],
						hirePeriod: row['Hire Period (wks)'],
						extendedHireRate: row[' Extended Hire Rate '],
					})
				}

				currentSection = product.replace(/^\d+\)\s*|:$/g, '').trim()
				sectionHasSubSection = false

				if (i + 1 < data.length && /^\d+\)/.test(data[i + 1]['Product'])) {
					processedData.push({
						section: currentSection,
						subSection: null,
						quantity: row['QTY'],
						unit: row['Unit'],
						totalRate: row['Total Rate'],
						hirePeriod: row['Hire Period (wks)'],
						extendedHireRate: row[' Extended Hire Rate '],
					})
					currentSection = ''
				}
			} else {
				if (currentSection) {
					sectionHasSubSection = true
					processedData.push({
						section: currentSection,
						subSection: product.trim(),
						quantity: row['QTY'],
						unit: row['Unit'],
						totalRate: row['Total Rate'],
						hirePeriod: row['Hire Period (wks)'],
						extendedHireRate: row[' Extended Hire Rate '],
					})
				}
			}
		}

		if (currentSection && !sectionHasSubSection) {
			processedData.push({
				section: currentSection,
				subSection: null,
				quantity: data[data.length - 1]['QTY'],
				totalRate: data[data.length - 1]['Total Rate'],
				hirePeriod: data[data.length - 1]['Hire Period (wks)'],
				extendedHireRate: data[data.length - 1][' Extended Hire Rate '],
			})
		}

		return processedData.filter((row) => row['quantity'] !== 'Excluded')
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

	return (
		<div className="pb-4">
			<div className="px-2" {...getRootProps()}>
				<div className="mt-1 flex justify-center px-6 py-2 border-2 border-gray-300 border-dashed rounded-md">
					<div className="space-y-1 text-center">
						<div className="flex text-sm text-gray-600">
							<div className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500">
								<span>Upload a file</span>
								<input type="file" className="sr-only" {...getInputProps()} />
							</div>
							<p className="pl-1">
								{isDragActive ? 'Drop file here' : 'or drag and drop'}
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* {fileName && <p className="mt-2">Uploaded file: {fileName}</p>} */}
			{fileLoading && <p>Loading...</p>}
		</div>
	)
}
