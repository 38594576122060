import { useContext, useEffect, useRef } from 'react'
import { Button, Spinner, Tabs, TextArea } from 'common'
import {
	SimpleQuoteServices,
	JobsServices,
	StaffServices,
	ContactServices,
	ClientServices,
} from 'services'
import {
	SectionsForm,
	RatesForm,
	SimpleQuoteLinesForm,
	SimpleQuoteAdditionalLines,
	Totals,
	InclusionsExclusions,
} from '../FormComponents'
import { useState } from 'react'
import { GeneralInfo } from '../FormComponents/GeneralInfo'
import { RatesAdmin } from '../FormComponents/Rates/RatesAdmin'
import SiteFiles from '../FormComponents/SiteFiles/SiteFiles'
import { NotificationsContext } from 'context/notifications/toastContext'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { FastSpinner } from 'common/Spinner/FastSpinner'
import TickBox from '../FormComponents/Tickbox/Tickbox'
import { InclusionsAndExclusions } from './InclusionsAndExclusions'

interface FormProps {
	quoteID?: number
}

interface CheckboxOption {
	text: string
	type: string
	checked: boolean
}

export const SimpleForm = ({ quoteID }: FormProps) => {
	const { showMultipleErrors } = useContext(NotificationsContext)
	const formRef = useRef<HTMLDivElement | null>(null)
	const [tabIndex, setTabIndex] = useState(0)

	const { data: quote_data, isLoading: quote_loading } =
		SimpleQuoteServices.useQuoteById(quoteID)

	const { data: quote_lines, isLoading: quote_lines_loading } =
		SimpleQuoteServices.useQuoteLinesById(quoteID)

	const { data: quote_addons, isLoading: quote_addons_loading } =
		SimpleQuoteServices.useQuoteAddonsById(quoteID)

	const { data: quote_zones, isLoading: quote_zones_loading } =
		SimpleQuoteServices.useQuoteZonesById(quoteID)

	const { data: quote_files, isLoading: quote_files_loading } =
		SimpleQuoteServices.useQuoteFilesById(quoteID)

	const { data: quote_rates, isLoading: quote_rates_loading } =
		SimpleQuoteServices.useQuoteRatesById(quoteID)

	const { data: jobs_data, isLoading: jobs_loading } = JobsServices.useJobs()

	const userState = useSelector((store: AppStore) => store.user)
	const clientsDataHook = ClientServices.useClients()
	const clientContactsDataHook = ContactServices.useContacts()
	const staffDataHook = StaffServices.useStaff()

	useEffect(() => {
		if (formik && clientsDataHook.data) {
			const defaultEstimator =
				staffDataHook.data?.find((staff) => staff.email === userState.email)
					?.id || 0
			formik.setFieldValue('estimator', defaultEstimator)
		}
	}, [staffDataHook.data])

	const {
		formik,
		quoteLinesHandlers,
		quoteAdditionalLinesHandlers,
		ratesHandlers,
		zonesHandlers,
		setBackToMainTable,
		itemsTabs,
	} = SimpleQuoteServices.useSimpleFormHandler({
		quote_id: quoteID,
		quote_data,
		quote_lines,
		quote_addons,
		quote_rates,
		quote_zones,
		quote_files,
	})

	const [sharedCheckBoxes, setSharedCheckBoxes] = useState<CheckboxOption[]>([])

	useEffect(() => {
		const parseCheckboxOptions = (
			textData: string,
			type: string
		): CheckboxOption[] => {
			try {
				const parsedData = JSON.parse(textData)
				return parsedData.map((item: any) => ({
					text: item.text,
					type: item.type,
					checked: item.checked || false,
				}))
			} catch (error) {
				return InclusionsAndExclusions.filter((option) => option.type === type)
			}
		}

		if (!quote_loading && quote_data) {
			const perimeterScaffolding = quote_data.perimeter_scaffolding || '[]'
			const general = quote_data.general || '[]'
			const manAndMaterialHoist = quote_data.man_and_material_hoist || '[]'

			const perimeterScaffoldingOptions = parseCheckboxOptions(
				perimeterScaffolding,
				'Perimeter Scaffolding'
			)
			const generalOptions = parseCheckboxOptions(general, 'General')
			const manAndMaterialHoistOptions = parseCheckboxOptions(
				manAndMaterialHoist,
				'Man & Material Hoist'
			)

			setSharedCheckBoxes([
				...perimeterScaffoldingOptions,
				...generalOptions,
				...manAndMaterialHoistOptions,
			])
		} else if (!quoteID) {
			setSharedCheckBoxes(InclusionsAndExclusions)
		}
	}, [quote_loading, quote_data])

	useEffect(() => {
		const perimeterScaffoldingOptions = sharedCheckBoxes.filter(
			(option) => option.type === 'Perimeter Scaffolding'
		)
		const generalOptions = sharedCheckBoxes.filter(
			(option) => option.type === 'General'
		)
		const manAndMaterialHoistOptions = sharedCheckBoxes.filter(
			(option) => option.type === 'Man & Material Hoist'
		)

		formik.setFieldValue(
			'perimeter_scaffolding',
			JSON.stringify(perimeterScaffoldingOptions)
		)
		formik.setFieldValue('general', JSON.stringify(generalOptions))
		formik.setFieldValue(
			'man_and_material_hoist',
			JSON.stringify(manAndMaterialHoistOptions)
		)
	}, [sharedCheckBoxes])

	if (
		quote_loading ||
		quote_lines_loading ||
		quote_addons_loading ||
		quote_zones_loading ||
		quote_rates_loading ||
		quote_files_loading ||
		jobs_loading
	) {
		return <Spinner />
	}

	return (
		<>
			<div className="mx-4 mb-10" ref={formRef}>
				<div className="w-full">
					<div className="flex">
						<div className="lg:w-4/6 md:w-full sm:w-full">
							<GeneralInfo
								formik={formik}
								jobs_data={jobs_data}
								staffDataHook={staffDataHook}
								clientContactsDataHook={clientContactsDataHook}
								clientsDataHook={clientsDataHook}
							/>
						</div>
						<div className="w-full">
							<div className="w-4/6 mx-auto">
								<div className="flex justify-start mb-4">
									<Tabs
										tabIndex={tabIndex}
										setTabIndex={setTabIndex}
										tabs={itemsTabs}
									/>
								</div>
								{tabIndex === 0 && (
									<SectionsForm
										formik={formik}
										sections={formik.values.zones}
										sectionsHandlers={zonesHandlers}
										errors={formik.errors}
									/>
								)}
								{tabIndex === 1 && (
									<RatesForm
										rates={formik.values.rates}
										ratesHandlers={ratesHandlers}
									/>
								)}
								{tabIndex === 2 && (
									<RatesAdmin
										rates={formik.values.rates}
										ratesHandlers={ratesHandlers}
									/>
								)}
							</div>
						</div>
					</div>

					<>
						<SimpleQuoteLinesForm
							formik={formik}
							zones={formik.values.zones}
							rates={formik.values.rates}
							quoteLines={formik.values.quote_lines}
							quoteLinesHandlers={quoteLinesHandlers}
						/>

						<SimpleQuoteAdditionalLines
							zones={formik.values.zones}
							rates={formik.values.rates}
							additionalLines={formik.values.quote_additional_lines}
							additionalLinesHandlers={quoteAdditionalLinesHandlers}
						/>

						<SiteFiles
							setFieldValue={formik.setFieldValue}
							values={formik.values}
						/>

						<div className="flex">
							<div className="lg:w-4/6 md:w-full sm:w-full">
								<InclusionsExclusions
									id={''}
									sharedCheckBoxes={sharedCheckBoxes}
									title="Scaffolding General Requirements"
								/>
							</div>
							<div className="lg:w-4/6 md:w-full sm:w-full">
								<TickBox
									sharedCheckBoxes={sharedCheckBoxes}
									setsharedCheckBoxes={setSharedCheckBoxes}
								/>
							</div>
						</div>

						{/* <div className="w-3/6">
							<TextArea
								title="Additional Conditions"
								id="terms"
								placeholder="Additional Conditions"
								handleBlur={formik.handleBlur}
								handleChange={formik.handleChange}
								type="text"
								value={formik.values.terms}
								error={formik.errors.terms}
								rows={10}
							/>
						</div> */}

						<Totals
							erectDismantleTotal={formik.values.erectDismantleTotal}
							additionalTotal={formik.values.additionalTotal}
							weekTotal={formik.values.weekTotal}
							total={formik.values.total}
						/>

						{quoteID && (
							<div className="pl-2 mt-6 flex space-x-4">
								<Button
									onClick={() => {
										setBackToMainTable(true)
										formik.handleSubmit()
									}}
									type="submit"
									form="quoteForm"
									//isLoading={quoteCreating}
									size="sm"
									variant={'primary'}>
									Save & Exit
								</Button>
								<Button
									onClick={() => formik.handleSubmit()}
									type="submit"
									form="quoteForm"
									size="sm"
									variant={'primary'}>
									Save & View
								</Button>
							</div>
						)}
						{!quoteID && (
							<div className="pl-2 mt-6">
								<Button
									onClick={() => {
										formik.handleSubmit()
										if (
											formik.errors &&
											Object.keys(formik.errors).length > 0 &&
											formRef.current
										) {
											if ('zones' in formik.errors) {
												delete formik.errors['zones']
												formik.errors.zones = 'Sections are required'
												setTabIndex(0)
											}
											const messages: string[] = ([] as string[]).concat(
												...(Object.values(formik.errors)
													.map((error) =>
														Array.isArray(error) ? error : [error]
													)
													.flat() as string[])
											)

											console.log('formik.errors', formik.errors)
											formRef.current.scrollIntoView({
												behavior: 'smooth',
												block: 'start',
											})

											showMultipleErrors(messages)
										}
									}}
									type="submit"
									form="quoteForm"
									size="sm"
									disabled={formik.isSubmitting}
									variant={'primary'}>
									<div className="flex items-center">
										{formik.isSubmitting ? (
											<div className="h-6 w-6 mr-2">
												<FastSpinner />
											</div>
										) : null}
										Create Quote
									</div>
								</Button>
							</div>
						)}
					</>
				</div>
			</div>
		</>
	)
}
