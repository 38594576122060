export const InclusionsAndExclusions = [
	{
		text: 'Supply and installation of openings',
		type: 'Perimeter Scaffolding',
		checked: false,
	},
	{
		text: 'Necessary scaffold ties to structure.',
		type: 'General',
		checked: false,
	},
	{
		text: 'Lap boards or plates to be used on all breaks.',
		type: 'General',
		checked: false,
	},
	{
		text: 'Scaffold will include double handrails outside and double handrails inside to comply with Managing of Falls Code of Practice 2015.',
		type: 'General',
		checked: false,
	},
	{
		text: 'The scaffolding system is to comply with the NSW Workcover Regulations and AS 1576.',
		type: 'General',
		checked: false,
	},
	{
		text: 'Provision of Engineering requirements and certification(1x certificate for each 8 decks)',
		type: 'General',
		checked: false,
	},
	{
		text: 'Engineered shopdrawings (3) three revisions maximum allowed',
		type: 'General',
		checked: false,
	},
	{
		text: 'Crane for erection and dismantle.',
		type: 'General',
		checked: false,
	},
	{
		text: 'Powerline Protection.',
		type: 'General',
		checked: false,
	},
	{
		text: 'Allowance for paint and livery to B Class Hoarding.',
		type: 'General',
		checked: false,
	},
	{
		text: 'Supply and installation of lighting to B Class Hoarding',
		type: 'General',
		checked: false,
	},
	{
		text: 'Council fees and associated compliance cost for B Class Hoarding.',
		type: 'General',
		checked: false,
	},
	{
		text: 'Traffic Management.',
		type: 'General',
		checked: false,
	},
	{
		text: 'Hop-up modification to facilitate shelf angle installation.',
		type: 'General',
		checked: false,
	},
	{
		text: 'Monthly inspection and maintenance.',
		type: 'Man & Material Hoist',
		checked: false,
	},
	{
		text: 'Engineering requirements and certification',
		type: 'Man & Material Hoist',
		checked: false,
	},
	{
		text: 'Necessary mesh panels on either side of each hoist gate',
		type: 'Man & Material Hoist',
		checked: false,
	},
	{
		text: 'Installation and removal of hoist ties',
		type: 'Man & Material Hoist',
		checked: false,
	},
	{
		text: 'Grind back all fixings installed.',
		type: 'Man & Material Hoist',
		checked: false,
	},
	{
		text: 'Install and remove all gates and ramps required',
		type: 'Man & Material Hoist',
		checked: false,
	},
	{
		text: 'Supply of all task lights.',
		type: 'Man & Material Hoist',
		checked: false,
	},
	{
		text: 'Install and remove all gates and ramps required.',
		type: 'Man & Material Hoist',
		checked: false,
	},
]
