import { PlusCircleIcon } from '@heroicons/react/24/solid'
import { Input, TextArea } from 'common'
import { FormikProps } from 'formik'
import { IQuoteZones, IZonesHandlers } from 'models'
import { IQuoteForm } from 'models/simple_quotes.model'
import React from 'react'

interface ZonesFormProps {
	formik: FormikProps<IQuoteForm>
	sections: IQuoteZones[]
	sectionsHandlers: IZonesHandlers
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors: any
	// for errors use FormikErrors<IQuoteForm>
}

export const SectionsForm = ({
	formik,
	sections,
	sectionsHandlers,
	errors,
}: ZonesFormProps) => {
	const sectionsArray = sections.filter(
		(item) => item.zone_label !== 'Additional'
	)
	return (
		<>
			<div className="grid grid-cols-2 items-start">
				{sectionsArray.map((section, index) => (
					<React.Fragment key={index}>
						<div className="flex items-center">
							<Input
								id={`${index + 1}`}
								title={`Section ${index + 1}`}
								labelInline
								placeholder={`Section ${index + 1}`}
								handleBlur={() => null}
								type="text"
								value={section.zone_label}
								error={errors.zones?.[index]?.zone_label}
								handleChange={(e) =>
									sectionsHandlers?.updateZone(
										index,
										'zone_label',
										e.target.value
									)
								}
							/>
						</div>
						{/* <div className="flex items-center">
							<TextArea
								title="Description"
								handleChange={(e) =>
									sectionsHandlers?.updateZone(
										index,
										'description',
										e.target.value
									)}
								placeholder="Description"
								id={`${index + 1} Description`}
								value={section.description}
								error={errors.zones?.[index]?.description}
								rows={5}
							/>
						</div> */}
					</React.Fragment>
				))}
				<div className="flex items-center mt-3">
					<PlusCircleIcon className="w-6 h-6 text-indigo-500" />
					<button
						type="button"
						onClick={() => formik.setFieldValue('max_zones', sectionsArray.length + 1)}>
						Add New Section
					</button>
				</div>
			</div>
		</>
	)
}
