import {
	IQuoteLine,
	IQuoteZones,
	IRates,
	IQuoteLinesHandlers,
	IQuoteForm,
} from 'models/simple_quotes.model'
import { SimpleQuoteLine } from './QuoteLine'
import { PlusIcon } from '@heroicons/react/24/solid'
import { CreateCSVFile } from 'common/UploadCSVFile/UploadCSVFile'
import { FormikProps } from 'formik'

interface QuoteLinesProps {
	formik: FormikProps<IQuoteForm>
	rates: IRates[]
	zones: IQuoteZones[]
	quoteLines: IQuoteLine[]
	quoteLinesHandlers: IQuoteLinesHandlers
}

export const SimpleQuoteLinesForm = ({
	formik,
	rates,
	zones,
	quoteLines,
	quoteLinesHandlers,
}: QuoteLinesProps) => {
	const quoteInputType = formik.values.quote_input_type
	const filteredColumns = quoteLinesHandlers
		.validateQuoteLinesColumns()
		.filter((column) => {
			if (
				quoteInputType === 'Imported' &&
				(column.dataIndex === 'type' ||
					column.dataIndex === 'length' ||
					column.dataIndex === 'height' ||
					column.dataIndex === 'erect_and_dismantle')
			) {
				return false
			}
			return true
		})

	return (
		<>
			<div className="w-full px-2">
				{quoteInputType === 'Imported' ? (
					<div className="w-1/5">
						<h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">
							Upload a CSV file
						</h2>
						<CreateCSVFile
							formik={formik}
							field="file_1"
							setFieldValue={formik.setFieldValue}
							value={formik.values.file_1}
						/>
					</div>
				) : null}
				<h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">
					Scaffolding, Propping & Optional Extras
				</h2>
				<table className="w-full divide-y divide-gray-200">
					<thead className="bg-gray-100">
						<tr>
							{filteredColumns.map((column) => (
								<th
									className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs"
									key={column.key}>
									{column.title}
								</th>
							))}
							{/* Additional Column to add de the delete line button */}
							<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs"></th>
						</tr>
					</thead>
					<tbody>
						{quoteLines.map((quoteLine: IQuoteLine, index: number) => (
							<SimpleQuoteLine
								key={index}
								zones={zones}
								rates={rates}
								data={quoteLine}
								index={index}
								quoteLinesHandlers={quoteLinesHandlers}
								quoteInputType={quoteInputType}
							/>
						))}
					</tbody>
				</table>
				<div className="mt-6 mb-16">
					<button
						type="button"
						className="flex items-center"
						onClick={() => quoteLinesHandlers.addNewQuoteLine()}>
						<PlusIcon
							className="flex-shrink-0 h-5 w-5 text-green-500"
							aria-hidden="true"
						/>
						<span className="ml-2 text-sm">Add Item</span>
					</button>
				</div>
			</div>
		</>
	)
}
