import React, { useEffect, useState } from 'react'
import { Checkbox } from 'common' // Import the updated Checkbox component

interface CheckboxOption {
	type: string
	text: string
	checked: boolean
}

interface TickBoxProps {
	sharedCheckBoxes: CheckboxOption[]
	setsharedCheckBoxes: any
}

const TickBox: React.FC<TickBoxProps> = ({
	sharedCheckBoxes,
	setsharedCheckBoxes,
}) => {
	const handleCheckboxChange = (id: string, values: string[]) => {
    const updatedState = sharedCheckBoxes.map((cb) => {
      if (values.includes(cb.text)) {
        return { ...cb, checked: true };
      } else if (cb.type === 'Perimeter Scaffolding' && id === 'perimeterScaffolding') {
        return { ...cb, checked: false };
      } else if (cb.type === 'General' && id === 'general') {
        return { ...cb, checked: false };
      } else if (cb.type === 'Man & Material Hoist' && id === 'manAndMaterialHoist') {
        return { ...cb, checked: false };
      }
      return cb;
    });

    setsharedCheckBoxes(updatedState);
  };

	const options = sharedCheckBoxes.map((cb) => ({
		value: cb.text,
		label: cb.text,
		type: cb.type,
	}))

	const selectedPerimeterScaffolding = sharedCheckBoxes
		.filter((cb) => cb.checked && cb.type === 'Perimeter Scaffolding')
		.map((cb) => cb.text)

	const selectedGeneral = sharedCheckBoxes
		.filter((cb) => cb.checked && cb.type === 'General')
		.map((cb) => cb.text)

	const selectedManAndMaterialHoist = sharedCheckBoxes
		.filter((cb) => cb.checked && cb.type === 'Man & Material Hoist')
		.map((cb) => cb.text)

	return (
		<div className="grid gap-4">
			<br />
			<h3 className="text-lg px-4 leading-6 font-large">Select Perimeter Scaffolding</h3>
			<Checkbox
				title=""
				options={options.filter((opt) => opt.type === 'Perimeter Scaffolding')}
				id="perimeterScaffolding"
				values={selectedPerimeterScaffolding}
				onChange={handleCheckboxChange}
			/>
			<br />
			<h3 className="text-lg px-4 leading-6 font-large">
				Select General
			</h3>
			<Checkbox
				title=""
				options={options.filter((opt) => opt.type === 'General')}
				id="general"
				values={selectedGeneral}
				onChange={handleCheckboxChange}
			/>
			<br />
			<h3 className="text-lg px-4 leading-6 font-large">
				Select Man & Material Hoist
			</h3>
			<Checkbox
				title=""
				options={options.filter((opt) => opt.type === 'Man & Material Hoist')}
				id="manAndMaterialHoist"
				values={selectedManAndMaterialHoist}
				onChange={handleCheckboxChange}
			/>
		</div>
	)
}

export default TickBox
