import { Text, View } from '@react-pdf/renderer'

import { styles } from './styles'
import { QuoteData } from 'models'
import moment from 'moment'

interface GreetingsProps {
	quote: QuoteData
}

export const Greetings = ({ quote }: GreetingsProps) => {
	const contactName = quote.contactData.name
	const estimatorName = quote.estimatorData.staff_name
	const estimatorMobile = quote.estimatorData.mobile
	const estimatorEmail = quote.estimatorData.email

	return (
		<View style={styles.headerContainer}>
			<View style={styles.heading}>
				{/* <View style={styles.headingDisplay}>
					<Text style={styles.headingText}>Attention: </Text>
					<Text style={styles.subText}>{contactName}</Text>
				</View>
				<View style={styles.headingDisplay}>
					<Text style={styles.headingText}>Email: </Text>
					<Text style={styles.subText}>{contactEmail}</Text>
				</View>
				<View style={styles.headingDisplay}>
					<Text style={styles.headingText}>Quote By: </Text>
					<Text style={styles.subText}>{estimatorName}</Text>
				</View> */}
				<Text style={styles.body}>
					Dear {contactName},
					{'\n\n'}Thank you for your valued enquiry for a Five Star Scaffolding
					solution for your project.
					{'\n\n'}Five Star Scaffolding specialises in providing innovative
					engineered access solutions for Commercial, Healthcare, Education,
					Industrial, Infrastructure and Mining projects across the greater
					Sydney area.
					{'\n\n'}As a result of working closely with our clients, on similar
					projects to yours, we have consistently managed to ensure {'>'}98%
					on-time completion rates over the last 18 months, and thereby helping
					them avoid costly delays.
					{'\n\n'}We achieve this through a strong focus on safety, continuous
					investment into employee skills development, ongoing review of our
					work methods and operational efficiency.
					{'\n\n'}Each project we undertake is site specific and governed by
					established safety and legislative requirements. We pride ourselves in
					being compliant to all Australian standards.
					{'\n\n'}We look forward to discussing your project in more detail with
					you.
					{'\n\n'}Yours Sincerely,
					{'\n\n'}{estimatorName}
					{'\n'}{estimatorMobile || ''}
					{'\n'}{estimatorEmail || ''}
				</Text>
				{/* <View style={styles.headingDisplay}>
					<Text style={styles.headingText}>Valid To: </Text>
					<Text style={styles.subText}>{validity}</Text>
				</View> */}
			</View>
		</View>
	)
}
