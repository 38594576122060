import { Document, Image, Link, Page, Text, View } from '@react-pdf/renderer'
import {
	QuoteData,
	IQuoteLine,
	IQuoteAdditionalLines,
	IQuoteZones,
} from 'models'
import { styles } from './Styles'
import { Heading, ClientInfo } from './Heading'
import { Description } from './Description'
import { ColumnTypes, Table } from './Table'
import { Greetings } from './Greetings'

interface QuotePDFDocumentProps {
	quote_data: QuoteData
	quote_lines: IQuoteLine[]
	quote_addons: IQuoteAdditionalLines[]
	quote_zones: IQuoteZones[]
}

export const QuotePDFDocument = ({
	quote_data,
	quote_lines,
	quote_addons,
	quote_zones,
}: QuotePDFDocumentProps) => {
	const linesTotal = quote_lines
		.map((line) => Number(line.total))
		.reduce((acc, curr) => acc + curr, 0)
	const linesHireTotal = quote_lines
		.map((line) => Number(line.weekly_hire_fee) * Number(line.hire_period))
		.reduce((acc, curr) => acc + curr, 0)
	const additionalTotal = quote_addons
		.map((addon) => Number(addon.total_cost))
		.reduce((acc, curr) => acc + curr, 0)
	const total = linesTotal + additionalTotal

	// const formattedQuoteLines = quote_zones
	// 	.map((zone: IQuoteZones) => {
	// 		const filteredLines = quote_lines.filter(
	// 			(line: IQuoteLine) => line.zone_id === zone.zone_id
	// 		)

	// 		const { total, weekly_hire_fee, hire_period } = filteredLines.reduce(
	// 			(acc, line) => {
	// 				acc.total += Number(line.total) || 0
	// 				acc.weekly_hire_fee +=
	// 					Number(line.weekly_hire_fee) * Number(line.hire_period) || 0
	// 				acc.hire_period =
	// 					line.hire_period > acc.hire_period
	// 						? line.hire_period
	// 						: acc.hire_period
	// 				return acc
	// 			},
	// 			{ total: 0, weekly_hire_fee: 0, hire_period: 0 }
	// 		)

	// 		return {
	// 			description: zone.zone_label,
	// 			filteredLines,
	// 			total,
	// 			weekly_hire_fee,
	// 			hire_period,
	// 		}
	// 	})
	// 	.filter((line: any) => line.total)

	const isImage = (fileUrl: string) => {
		if (!fileUrl) return false
		const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
		const extension = fileUrl.split('.').pop()?.toLowerCase()
		return extension ? imageExtensions.includes(extension) : false
	}

	const parseCheckboxOptions = (textData: string): any => {
		try {
			const parsedData = JSON.parse(textData)
			return parsedData.map((item: any) => ({
				text: item.text,
				type: item.type,
				checked: item.checked || false,
			}))
		} catch (error) {
			return textData
		}
	}

	const perimeterScaffolding = parseCheckboxOptions(
		quote_data.perimeter_scaffolding
	)
	const general = parseCheckboxOptions(quote_data.general)
	const manAndMaterialHoist = parseCheckboxOptions(
		quote_data.man_and_material_hoist
	)

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<Heading />
				<Text style={styles.quoteText}>Quotation</Text>
				<ClientInfo quote={quote_data} />
				<Greetings quote={quote_data} />
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				<Description description={quote_data.scope_of_work} />
				<View>
					<Text style={styles.heading}>Quote Lines</Text>
					<Table type={ColumnTypes.quote_lines} data={quote_lines} />
				</View>
				<View>
					<Text style={styles.heading}>Special notes</Text>
					<Table type={ColumnTypes.quote_addons} data={quote_addons} />
				</View>
				<View style={{ marginTop: 20 }}>
					<Table type={ColumnTypes.totals} data={total} />
				</View>
			</Page>
			{(perimeterScaffolding.some((item: { checked: any }) => item.checked) ||
				general.some((item: { checked: any }) => item.checked) ||
				manAndMaterialHoist.some((item: { checked: any }) => item.checked)) && (
				<Page size="A4" style={styles.page}>
					<Heading />
					<>
						{perimeterScaffolding.filter(
							(item: { checked: any }) => item.checked
						).length ? (
							<>
								<Text style={styles.heading}>Perimeter Scaffolding</Text>
								{perimeterScaffolding
									.filter((item: { checked: any }) => item.checked)
									.map((item: { text: string }) => (
										<Text style={{ ...styles.subTextMargin, marginLeft: 2 }}>
											{item.text}
										</Text>
									))}
							</>
						) : null}
						<Text style={{ marginBottom: 8 }} />
					</>
					<>
						{general.filter((item: { checked: any }) => item.checked).length ? (
							<>
								<Text style={styles.heading}>General</Text>
								{general
									.filter((item: { checked: any }) => item.checked)
									.map((item: { text: string }) => (
										<Text style={{ ...styles.subTextMargin, marginLeft: 2 }}>
											{item.text}
										</Text>
									))}
							</>
						) : null}
						<Text style={{ marginBottom: 8 }} />
					</>
					<>
						{manAndMaterialHoist.filter(
							(item: { checked: any }) => item.checked
						).length ? (
							<>
								<Text style={styles.heading}>Man & Material Hoist</Text>
								{manAndMaterialHoist
									.filter((item: { checked: any }) => item.checked)
									.map((item: { text: string }) => (
										<Text style={{ ...styles.subTextMargin, marginLeft: 2 }}>
											{item.text}
										</Text>
									))}
							</>
						) : null}
					</>
					<Text style={{ ...styles.subTextMargin }}>
						<Link src="https://fivestar.scaffm8.co.nz/TermsAndConditions.pdf">
							View our Terms and Conditions here
						</Link>
					</Text>
				</Page>
			)}
			{/* <Page size="A4" style={styles.page}>
				<Heading />
				<View>
					<Text style={styles.heading}>Additional Conditions</Text>
					<Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
						{quote_data.terms}
					</Text>
					<Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
						<Link src="https://fivestar.scaffm8.co.nz/TermsAndConditions.pdf">
							View our Terms and Conditions here
						</Link>
					</Text>
				</View>
			</Page> */}
			{quote_data.files.length > 0 ? (
				<Page size="A4" style={styles.page}>
					<View style={styles.imageRow}>
						{quote_data?.files?.map(
							(
								image: {
									url: string
									description: string
									section_label: string
								},
								index: number
							) => (
								<View style={styles.image}>
									{isImage(image.url) ? (
										<Image
											key={index}
											src={image.url}
											style={{ maxWidth: '100%', maxHeight: '100%' }}
										/>
									) : (
										<Link src={image.url}>
											<Text
												style={{
													color: 'blue',
													textDecoration: 'underline',
												}}>
												Click here to view file
											</Text>
										</Link>
									)}
									<View
										style={{
											flexDirection: 'row',
											gap: 5,
											alignItems: 'center',
											marginTop: 5,
										}}>
										<Text
											style={{
												...styles.subTextBold,
												fontSize: 10,
												margin: 0,
											}}>
											Description:
										</Text>
									</View>
									<Text style={styles.imageText}>{image.description}</Text>
									<View
										style={{
											flexDirection: 'row',
											gap: 5,
											alignItems: 'center',
											marginTop: 50,
										}}>
										<Text
											style={{
												...styles.subTextBold,
												fontSize: 10,
												margin: 0,
											}}>
											Section:
										</Text>
									</View>
									<Text style={styles.imageText}>{image.section_label}</Text>
								</View>
							)
						)}
					</View>
				</Page>
			) : null}
		</Document>
	)
}
